const ObjectToFormData = (obj, formData = new FormData(), name = '')=>{
    Object.keys(obj).forEach(key=>{
        if(obj[key] !== false && !obj[key]) return;

        if(typeof obj[key] === 'string' || typeof obj[key] === 'number' 
                    || typeof obj[key] === 'boolean' || obj[key] instanceof Blob) {
            const keyName = name ? `${name}.${key}` : key;
            formData.append(keyName, obj[key]);
        }
        else if(obj[key] instanceof Array) {//배열 -> name[index]
            obj[key].forEach((item,idx)=>{
                ObjectToFormData(item, formData, name ? `${name}[${idx}]` : `${key}[${idx}]`);
            });
        }
        else{//객체 -> name[key]
            ObjectToFormData(obj[key], formData, name ? `${name}[${key}]` : key);
        }
    });
};

export {ObjectToFormData}