const Jumbotron = ({children, bg='dark', text='light', rounded=true, ...otherProps})=>{
    return <div className={`p-4 text-light ${bg ? `bg-${bg}` : ''} ${text ? `text-${text}` : ''} rounded`} {...otherProps}>{children}</div>
};

Jumbotron.Title = ({children})=>{
    return <h1>{children}</h1>
};

Jumbotron.Content = ({children})=>{
    return <div className="mt-4 mb-0">{children}</div>
};

Jumbotron.Highlight = ({children, variant='primary'})=>{
    return <span className={`text-light bg-${variant} rounded ps-1 pe-1 d-inline-flex justify-content-between align-items-center`}>{children}</span>
};

export default Jumbotron;