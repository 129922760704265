import { Col, Form, Image, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useCallback } from 'react';
import axios from '@util/ajax/AxiosManager';
import { ObjectToFormData } from '@util/ajax/FormDataManager';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomSunEditor from '@component/fragment/rich-text-editor/CustomSunEditor';

const PortfolioInsert = ()=>{
    const [data, setData] = useState({
        name:'',
        url:'',
        logo:null,
        explain:'',
    });
    const [logoPreview, setLogoPreview] = useState(null);
    const [collaborators, setCollaborators] = useState([]);
    const [references, setReferences] = useState([]);
    const [storages, setStorages] = useState([]);
    const [accounts, setAccounts] = useState([]);

    //일반 데이터 입력 처리도구
    const dataInputProcessor = useCallback(e=>{
        const name = e.target.name;
        const value = e.target.value;
        setData({
            ...data,
            [name]:value
        });
    });

    //SunEditor 입력 처리도구
    const explainInputProcessor = useCallback(value=>{
        setData({
            ...data,
            ['explain']:value
        });
    });

    //파일 입력 처리도구
    const fileInputProcessor = useCallback(e=>{
        const name = e.target.name;
        const files = e.target.files;
        if(files.length > 1) return;
        const file = files.length == 0 ? null : files[0];
        const url = files.length == 0 ? null : window.URL.createObjectURL(file);
        setData({
            ...data,
            [name]:file
        });
        setLogoPreview(url);
    });

    //팀원 추가
    const addCollaborator = useCallback(e=>{
        setCollaborators(collaborators.concat({
            name:'',
            url:'',
            captain:false
        }));
    });

    //팀원 정보 수정
    const inputCollaborator = useCallback((e, index)=>{
        setCollaborators(collaborators.map((collaborator, i)=>{
            if(i == index) {
                const name = e.target.name;
                const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
                return {
                    ...collaborator,
                    [name]:value
                }
            }
            return collaborator;
        }));
    });

    //팀원 삭제
    const deleteCollaborator = useCallback((index)=>{
        const choice = window.confirm("해당 팀원 정보를 삭제하시겠습니까?");
        if(!choice) return;
        setCollaborators(collaborators.filter((c, i)=>i!==index));
    });

    //참조사이트 추가
    const addReference = useCallback(e=>{
        setReferences(references.concat({
            name:'',
            url:''
        }));
    });

    //참조사이트 수정
    const inputReference = useCallback((e,index)=>{
        setReferences(references.map((reference,i)=>{
            if(index == i) {
                const name = e.target.name;
                const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
                return {
                    ...reference,
                    [name]:value
                }
            }
            return reference;
        }));
    });

    //참조사이트 삭제
    const deleteReference = useCallback(index=>{
        const choice = window.confirm("해당 참조사이트 정보를 삭제하시겠습니까?");
        if(!choice) return;
        setReferences(references.filter((c,i)=>i!==index));
    });

    //저장소 추가
    const addStorage = useCallback(e=>{
        setStorages(storages.concat({
            type:'',
            url:''
        }));
    });

    //저장소 정보 수정
    const inputStorage = useCallback((e, index)=>{
        setStorages(storages.map((storage, i)=>{
            if(i == index) {
                const name = e.target.name;
                const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
                return {
                    ...storage,
                    [name]:value
                }
            }
            return storage;
        }));
    });

    //저장소 삭제
    const deleteStorage = useCallback((index)=>{
        const choice = window.confirm("해당 저장소 정보를 삭제하시겠습니까?");
        if(!choice) return;
        setStorages(storages.filter((c, i)=>i!==index));
    });

    //계정 추가
    const addAccount = useCallback(e=>{
        setAccounts(accounts.concat({
            type:'',
            username:'',
            password:''
        }));
    });

    //저장소 정보 수정
    const inputAccount = useCallback((e, index)=>{
        setAccounts(accounts.map((account, i)=>{
            if(i == index) {
                const name = e.target.name;
                const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
                return {
                    ...account,
                    [name]:value
                }
            }
            return account;
        }));
    });

    //저장소 삭제
    const deleteAccount = useCallback((index)=>{
        const choice = window.confirm("해당 저장소 정보를 삭제하시겠습니까?");
        if(!choice) return;
        setAccounts(accounts.filter((c, i)=>i!==index));
    });

    //모든 입력 초기화
    const clearAllInput = useCallback(()=>{
        setData({
            name:'',
            url:'',
            logo:null,
            explain:'',
        });
        setLogoPreview(null);
        setCollaborators([]);
        setReferences([]);
        setStorages([]);
        setAccounts([]);
    });

    //포트폴리오 등록
    const savePortfolio = useCallback(e=>{
        if(!data.name || !data.url) {
            window.alert("필수 정보를 작성하세요")
            return;
        }
        if(collaborators.length > 0) {
            const filterList = collaborators.filter(c=>{
                console.log(c.name.length);
                return c.name.length > 0});
            if(collaborators.length > filterList.length) {
                window.alert("팀원 이름을 작성하세요");
                return;
            }
            data.collaborators = collaborators;
        }
        if(references.length > 0) {
            const filterList = references.filter(r=>r.name.length > 0 && r.url.length > 0);
            if(references.length > filterList.length) {
                window.alert("참조 사이트 정보를 작성하세요");
                return;
            }
            data.references = references;
        }
        if(storages.length > 0){
            const filterList = storages.filter(r=>r.type.length > 0 && r.url.length > 0);
            if(storages.length > filterList.length) {
                window.alert("저장소 정보를 작성하세요");
                return;
            }
            data.storages = storages;
        }
        if(accounts.length > 0) {
            const filterList = accounts.filter(a=>a.type.length>0&&a.username.length>0&&a.password.length>0);
            if(accounts.length > filterList.length) {
                window.alert("계정 정보를 작성하세요");
                return;
            }
            data.accounts = accounts;
        }

        //FormData 변환 (미변환시 서버에서 인식 불가)
        const formData = new FormData();
        ObjectToFormData(data, formData);
        
        //등록
        axios.post(`${process.env.REACT_APP_AJAX_ROOT_URL}/portfolio/`, formData, {
            headers:{
                "Content-Type":"multipart/form-data"
            }
        })
        .then(response=>{
            //console.log(response);
            toast.success('등록이 완료되었습니다', {position:'bottom-right', autoClose:5000, theme:'colored'});
            clearAllInput();
        })
        .catch(error=>{
            //console.log(error);
            toast.error('등록 오류가 발생했습니다', {position:'bottom-right', autoClose:5000, theme:'colored'});
        });
    });

    //SunEditor 파일 업로드(구현예정)
    const imageUploadProcessor = (a, b, c, d, e, f)=>{
        console.log(a, b, c, d, e, f);
    };

    return(<>
        <Row>
            <Col>
                <h1>포트폴리오 등록</h1>
            </Col>
        </Row>
        <Form onSubmit={e=>e.preventDefault()} autoComplete="off">

            <fieldset className="mt-2 mb-2">
                <legend>프로젝트 기본 정보</legend>

                {/* 프로젝트 이름 등록란 */}
                <Form.Floating className="mt-4">
                    <Form.Control id="name-input" name="name" placeholder="comment" className="rounded" value={data.name} onChange={dataInputProcessor}/>
                    <Form.Label htmlFor="name-input" className="text-muted">
                        프로젝트 이름
                        <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                    </Form.Label>
                </Form.Floating>

                {/* 프로젝트 주소 등록란 */}
                <Form.Floating className="mt-4">
                    <Form.Control id="url-input" name="url" placeholder="comment" className="rounded" value={data.url} onChange={dataInputProcessor}/>
                    <Form.Label htmlFor="url-input" className="text-muted">
                        프로젝트 배포 주소
                        <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                    </Form.Label>
                </Form.Floating>

                {/* 프로젝트 로고 */}
                <Form.Group className="mt-4">
                    <Form.Label>프로젝트 로고</Form.Label>
                    <Form.Control type="file" size="lg" name='logo' accept='image/*' key={data.logo} onChange={fileInputProcessor}/>
                    {logoPreview != null && <Image height={50} src={logoPreview} className="mt-2"/>}
                </Form.Group>

                {/* 프로젝트 설명 등록란 - rich text editor로 변경 예정 */}
                <Form.Group className="mt-4">
                    {/* <Form.Control id="explain-input" name="explain" placeholder="comment" className="rounded" value={data.explain} onChange={dataInputProcessor}/> */}
                    <CustomSunEditor value={data.explain} onChange={explainInputProcessor} placeholder="프로젝트 설명"
                        onImageUpload={imageUploadProcessor}/>
                </Form.Group>
            </fieldset>

            <hr/>

            <fieldset className="mt-2 mb-2">
                <legend>
                    팀원 정보
                    <FontAwesomeIcon icon="fa-solid fa-plus" className='ms-2 text-primary' onClick={addCollaborator}/>
                </legend>

                {collaborators.map((collaborator, index)=>(
                <Form.Group key={index} className="mt-4 border p-2">
                    {/* 팀원 라벨 */}
                    <legend>
                        {index+1} 번째 팀원
                        <FontAwesomeIcon icon="fa-solid fa-xmark" className="ms-2" onClick={e=>deleteCollaborator(index)}/>
                    </legend>
                    {/* 팀원 이름 입력 */}
                    <Form.Floating className="mt-4">
                        <Form.Control name="name" placeholder="comment" className="rounded" value={collaborator.name} onChange={e=>inputCollaborator(e, index)}/>
                        <Form.Label className="text-muted">
                            이름
                            <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                        </Form.Label>
                    </Form.Floating>
                    <Form.Floating className="mt-4">
                        <Form.Control name="url" placeholder="comment" className="rounded" value={collaborator.url} onChange={e=>inputCollaborator(e, index)}/>
                        <Form.Label className="text-muted">
                            개인 홈페이지 주소
                        </Form.Label>
                    </Form.Floating>
                    <Form.Group className="mt-4">
                        <Form.Check type="switch" name="captain" label="조장" checked={collaborator.captain} onChange={e=>inputCollaborator(e, index)}></Form.Check>
                    </Form.Group>
                </Form.Group>
                ))}

            </fieldset>

            <hr/>

            <fieldset className="mt-2 mb-2">
                <legend>
                    참조한 사이트
                    <FontAwesomeIcon icon="fa-solid fa-plus" className='ms-2 text-primary' onClick={addReference}/>
                </legend>

                {references.map((reference, index)=>(
                    <Form.Group key={index} className="mt-4 p-2 border">
                        <legend>
                            {index+1} 번째 사이트
                            <FontAwesomeIcon icon="fa-solid fa-xmark" className="ms-2" onClick={e=>deleteReference(index)}/>    
                        </legend>

                        <Form.Floating className="mt-4">
                            <Form.Control name="name" placeholder="comment" className="rounded" value={reference.name} onChange={e=>inputReference(e,index)}/>
                            <Form.Label className="text-muted">
                                홈페이지 이름
                                <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                            </Form.Label>
                        </Form.Floating>
                        <Form.Floating className="mt-4">
                            <Form.Control name="url" placeholder="comment" className="rounded" value={reference.url} onChange={e=>inputReference(e,index)}/>
                            <Form.Label className="text-muted">
                                홈페이지 메인 주소
                                <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                            </Form.Label>
                        </Form.Floating>
                    </Form.Group>
                ))}

            </fieldset>

            <hr/>

            <fieldset className="mt-2 mb-2">
                <legend>
                    저장소 정보
                    <FontAwesomeIcon icon="fa-solid fa-plus" className='ms-2 text-primary' onClick={addStorage}/>
                </legend>

                {storages.map((storage, index)=>(
                    <Form.Group key={index} className="mt-4 p-2 border">
                        <legend>
                            {index+1} 번째 저장소
                            <FontAwesomeIcon icon="fa-solid fa-xmark" className="ms-2" onClick={e=>deleteStorage(index)}/>    
                        </legend>

                        <Form.Floating className="mt-4">
                            <Form.Control name="type" placeholder="comment" className="rounded" value={storage.type} onChange={e=>inputStorage(e,index)}/>
                            <Form.Label className="text-muted">
                                저장소 구분
                                <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                            </Form.Label>
                        </Form.Floating>
                        <Form.Floating className="mt-4">
                            <Form.Control name="url" placeholder="comment" className="rounded" value={storage.url} onChange={e=>inputStorage(e,index)}/>
                            <Form.Label className="text-muted">
                                저장소 주소
                                <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                            </Form.Label>
                        </Form.Floating>
                    </Form.Group>
                ))}
              
            </fieldset>

            <hr/>

            <fieldset className="mt-2 mb-2">
                <legend>
                    계정 정보
                    <FontAwesomeIcon icon="fa-solid fa-plus" className='ms-2 text-primary' onClick={addAccount}/>
                </legend>

                {accounts.map((account, index)=>(
                    <Form.Group key={index} className="mt-4 p-2 border">
                        <legend>
                            {index+1} 번째 계정
                            <FontAwesomeIcon icon="fa-solid fa-xmark" className="ms-2" onClick={e=>deleteAccount(index)}/>    
                        </legend>

                        <Form.Floating className="mt-4">
                            <Form.Control name="type" placeholder="comment" className="rounded" value={account.type} onChange={e=>inputAccount(e,index)}/>
                            <Form.Label className="text-muted">
                                계정 종류
                                <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                            </Form.Label>
                        </Form.Floating>
                        <Form.Floating className="mt-4">
                            <Form.Control name="username" placeholder="comment" className="rounded" value={account.username} onChange={e=>inputAccount(e,index)}/>
                            <Form.Label className="text-muted">
                                아이디
                                <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                            </Form.Label>
                        </Form.Floating>
                        <Form.Floating className="mt-4">
                            <Form.Control name="password" placeholder="comment" className="rounded" value={account.password} onChange={e=>inputAccount(e,index)}/>
                            <Form.Label className="text-muted">
                                비밀번호
                                <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                            </Form.Label>
                        </Form.Floating>
                    </Form.Group>
                ))}

                <hr/>
                
                <Button variant="success" size='lg' className='w-100' onClick={savePortfolio}>
                    <FontAwesomeIcon icon="fa-solid fa-floppy-disk" className="me-2"/>
                    포트폴리오 등록
                </Button>

            </fieldset>

        </Form>

        <ToastContainer/>

    </>);
};

export default PortfolioInsert;