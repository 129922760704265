import { Col, Row } from "react-bootstrap";
import Jumbotron from '@component/fragment/Jumbotron';

const PageNotFound = props=>{
    return (<>
        <Row>
            <Col>
                <Jumbotron bg={null} text={'dark'}>
                    <Jumbotron.Title>Access Denied</Jumbotron.Title>
                    <Jumbotron.Content><span className="text-muted">해당 페이지 이용 권한이 없습니다</span></Jumbotron.Content>
                </Jumbotron>
            </Col>
        </Row>
    </>);
};

export default PageNotFound;