//회원 및 관리자 라우터
import { useContext } from 'react';
import { Navigate } from 'react-router';
import ContextStore from '@util/store/context';

const MemberRoute = ({needAdmin=false, children})=>{
    const {login, admin} = useContext(ContextStore);
    if(login) {
        if(needAdmin) {
            console.log("needAdmin", needAdmin, "admin", admin);
            return admin ? <>{children}</> : <Navigate to="/access-denied"/>;
        }
        return <>{children}</>
    }
    else {
        return <Navigate to="/login/user"></Navigate>
    }
};

export default MemberRoute;