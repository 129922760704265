import './FileDropZone.css';

import { useState, useEffect, useMemo, useCallback } from 'react';
import axios from '@util/ajax/AxiosManager';
import { Row, Col, ListGroup, Button, Badge, Modal, Form } from 'react-bootstrap';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileUploader } from 'react-drag-drop-files';

const FileDropBox = ({ onDrop }) => {
    return (
        <FileUploader handleChange={onDrop} name="file" types={["WAR"]}
            label={'클릭하거나 드래그하여 WAR 파일을 추가하세요'} multiple={false} classes="file-drop-zone"/>
    );
};
const AddDeployModal = ({ show, setShow, onSuccess }) => {
    const [data, setData] = useState({
        path:'',
        war:null
    });

    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        setData({path:'', war:null});
    }, [show]);

    const inputPathProcessor = e=>{
        setData({
            ...data,
            ['path']:e.target.value
        });
    };
    const selectFileProcessor = file => {
        setData({
            ...data,
            war:file
        });
    };

    const isValid = useMemo(()=>{
        if(!/^\/[a-z0-9-_]+$/.test(data.path)) {
            return false;
        }

        if(data.war === null) {
            return false;
        }

        return true;
    }, [data]);

    const addDeployProcessor = e => {
        if(isValid) {
            setLoading(true);
            const formData = new FormData();
            formData.append("path", data.path);
            formData.append("war", data.war);
            axios.post(`${process.env.REACT_APP_AJAX_ROOT_URL}/deploy/add`, formData)
            .then(response=>{
                console.log("response", response);
                setLoading(false);
                if(onSuccess) {
                    onSuccess(true);
                }
                else {
                    setShow(false);
                }
            })
            .catch(error=>{
                setLoading(false);
                if(onSuccess) {
                    onSuccess(false);
                }
            });
        }
        else {
            window.alert("등록 데이터 오류");
        }
    };

    return (
        <>
            <Modal show={show} onHide={e=>setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>신규 배포 프로젝트 등록</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* 프로젝트 배포 경로 */}
                    <Form.Floating className="mt-4">
                        <Form.Control id="path-input" name="path" placeholder="comment" className="rounded" onChange={inputPathProcessor} autoComplete="off"/>
                        <Form.Label htmlFor="path-input" className="text-muted">
                            프로젝트 배포주소( <b>/</b> 로 시작)
                            <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-2"/>
                        </Form.Label>
                    </Form.Floating>
                    <Row className="mt-4">
                        <Col>
                            <FileDropBox onDrop={selectFileProcessor} />
                        </Col>
                    </Row>
                    {data.war !== null && <Row className="mt-2">
                        <Col>
                            <div className="border border-primary p-3 text-primary">
                                <FontAwesomeIcon icon="fa-solid fa-file" className="me-2"/>
                                {data.war.name}
                            </div>
                        </Col>
                    </Row>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e=>setShow(false)} style={ {width:60} }>
                        닫기
                    </Button>
                    <Button variant="success" onClick={addDeployProcessor} style={ {width:60} }>
                        {loading ? 
                        <FontAwesomeIcon icon="fa-solid fa-sync" className="fa-spin" /> 
                        : '등록'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const PortfolioDeploy = () => {
    const [deployList, setDeployList] = useState([]);
    const [addModalShow, setAddModalShow] = useState(false);

    const afterAddDeploy = useCallback((success)=>{
        console.log(success);
        if(success) {
            loadDeployList();
            setKeyword('');
            toast.success('프로젝트가 등록되었습니다', { position: 'bottom-right', autoClose: 3000, theme: 'colored' });
        }
        else {
            toast.error('프로젝트 등록 오류가 발생했습니다', { position: 'bottom-right', autoClose: 3000, theme: 'colored' });
        }
        setAddModalShow(false);
    });

    const startDeployProcessor = app => {
        setDeployList(deployList.map(deploy => {
            if (deploy.context === app.context) {
                return {
                    ...deploy,
                    ["startLoading"]: true
                }
            }
            return deploy;
        }));

        axios.put(`${process.env.REACT_APP_AJAX_ROOT_URL}/deploy/start`, app)
            .then(response => {
                const result = response.data;
                setDeployList(deployList.map(deploy => {
                    if (deploy.context === result.context) {
                        return result;
                    }
                    return deploy;
                }));
                toast.success('프로젝트가 시작되었습니다', { position: 'bottom-right', autoClose: 3000, theme: 'colored' });
            })
            .catch(error => {
                toast.error('프로젝트 시작 오류가 발생했습니다', { position: 'bottom-right', autoClose: 3000, theme: 'colored' });
            });
    };
    const stopDeployProcessor = app => {
        setDeployList(deployList.map(deploy => {
            if (deploy.context === app.context) {
                const replacement = {
                    ...deploy,
                    ["stopLoading"]: true
                };
                return replacement;
            }
            return deploy;
        }));

        axios.put(`${process.env.REACT_APP_AJAX_ROOT_URL}/deploy/stop`, app)
            .then(response => {
                const result = response.data;
                setDeployList(deployList.map(deploy => {
                    if (deploy.context === result.context) {
                        return result;
                    }
                    return deploy;
                }));
                toast.success('프로젝트가 정지되었습니다', { position: 'bottom-right', autoClose: 3000, theme: 'colored' });
            })
            .catch(error => {
                toast.error('프로젝트 정지 오류가 발생했습니다', { position: 'bottom-right', autoClose: 3000, theme: 'colored' });
            });
    };
    const changeDeployProcessor = app => {
        
    };
    const removeDeployProcessor = app => {
        const choice = window.confirm("정말 제거하시겠습니까?");
        if (!choice) return;

        setDeployList(deployList.map(deploy => {
            if (deploy.context === app.context) {
                const replacement = {
                    ...deploy,
                    ["removeLoading"]: true
                };
                return replacement;
            }
            return deploy;
        }));

        axios.put(`${process.env.REACT_APP_AJAX_ROOT_URL}/deploy/remove`, app)
            .then(response => {
                setDeployList(deployList.filter(deploy=>deploy.context !== app.context));
                toast.success('프로젝트가 제거되었습니다', { position: 'bottom-right', autoClose: 3000, theme: 'colored' })
            })
            .catch(error => toast.error('프로젝트 제거 과정에서 오류가 발생했습니다', { position: 'bottom-right', autoClose: 3000, theme: 'colored' }));
    };

    const openAddDeployModal = e => {
        setAddModalShow(true);
    };

    const loadDeployList = useCallback(()=>{
        axios.get(`${process.env.REACT_APP_AJAX_ROOT_URL}/deploy/`)
            .then(response => {
                setDeployList(response.data);
            });
    });

    useEffect(loadDeployList, []);

    const [keyword, setKeyword] = useState('');
    const filterList = useMemo(()=>{
        if(keyword.length === 0) 
            return deployList;

        return deployList.filter(deploy => deploy.context.indexOf(keyword) >= 0);
    }, [deployList, keyword]);

    return (<>
        <Row>
            <Col>
                <h1>Server control panel</h1>
                <p>
                    총 {deployList.length}개의 프로젝트가 등록되어 있습니다
                    <Button variant='primary' onClick={openAddDeployModal} className="ms-3">
                        <FontAwesomeIcon icon="fa-solid fa-circle-plus" className="me-2" />
                        신규 프로젝트 추가
                    </Button>
                </p>
            </Col>
        </Row>

        <Form.Floating className="mt-4">
            <Form.Control id="keyword-input" name="keyword" placeholder="comment" className="rounded border border-dark" autoComplete="off" onChange={e=>setKeyword(e.target.value)}/>
            <Form.Label htmlFor="keyword-input" className="text-muted">
                path 검색
                <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-2"/>
            </Form.Label>
        </Form.Floating>

        <Row className="mt-4">
            <Col>
                <ListGroup>
                    {filterList.map(app => (
                        <ListGroup.Item key={app.context} className="d-flex justify-content-between">
                            <div>
                                {app.context}
                            </div>
                            <div>
                                <Badge className="ms-2" bg={app.status === 'running' ? 'success' : 'danger'}>{app.status}</Badge>
                                {app.status === 'running' ?
                                    <Button variant="warning" className="ms-2" onClick={e => stopDeployProcessor(app)} style={{ width: 60 }} disabled={app.stopLoading === true}>
                                        {app.stopLoading === true ?
                                            <FontAwesomeIcon icon="fa-solid fa-sync" className="fa-spin" />
                                            : '정지'}
                                    </Button> :
                                    <Button variant="success" className="ms-2" onClick={e => startDeployProcessor(app)} style={{ width: 60 }} disabled={app.startLoading === true}>
                                        {app.startLoading === true ?
                                            <FontAwesomeIcon icon="fa-solid fa-sync" className="fa-spin" />
                                            : '시작'}
                                    </Button>
                                }
                                {/* <Button variant="warning" className="ms-2" onClick={e => changeDeployProcessor(app)} style={{ width: 60 }}>교체</Button> */}
                                <Button variant="danger" className="ms-2" onClick={e => removeDeployProcessor(app)} style={{ width: 60 }}>
                                    {app.removeLoading === true ? 
                                        <FontAwesomeIcon icon="fa-solid fa-sync" className="fa-spin" />
                                        :'제거'}
                                </Button>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Col>
        </Row>

        <AddDeployModal show={addModalShow} setShow={setAddModalShow} onSuccess={afterAddDeploy}/>
        <ToastContainer />
    </>);
};

export default PortfolioDeploy;