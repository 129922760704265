// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, GithubAuthProvider, getAuth, signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import axios from '@util/ajax/AxiosManager';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;

const createProvider = (providerName)=>{
    switch(providerName.toLowerCase()) {
        case 'google': return new GoogleAuthProvider();
        case 'github': return new GithubAuthProvider();
    }
};
const SocialLogin = (providerName, successCallback, errorCallback)=>{
    const provider = createProvider(providerName);
    const auth = getAuth();
    signInWithPopup(auth, provider)
    .then(result=>{
        const memberInfo = result.user;
        //ajax request to server
        axios.post(`${process.env.REACT_APP_AJAX_ROOT_URL}/member/login`, memberInfo, {
            headers:{"Content-Type":"application/json"}
        })
        .then(response=>{
            //save to local storage
            const member = response.data;
            // window.localStorage.setItem("member", JSON.stringify({email:member.email, token:member.accessToken}));
            successCallback(member);
        })
        .catch(errorCallback);
    })
    .catch(errorCallback);
}
const SocialLogout = (successCallback, errorCallback)=>{
    const auth = getAuth();
    signOut(auth).then(successCallback, errorCallback);
}
const SocialLoginAndRefresh = (successCallback, errorCallback)=>{
    const auth = getAuth();
    onAuthStateChanged(auth, (memberInfo)=>{
        if(!memberInfo) return;

        axios.post(`${process.env.REACT_APP_AJAX_ROOT_URL}/member/refresh`, memberInfo, {
            headers:{"Content-Type":"application/json"}
        })
        .then(response=>{
            //save to local storage
            const member = response.data;
            if(member.trim().length == 0) {
                window.localStorage.removeItem("member");
            }
            else {
                window.localStorage.setItem("member", JSON.stringify({email:member.email, token:member.accessToken}));
            }
            successCallback(member);
        })
        .catch(errorCallback);
    });
};

export {SocialLogin, SocialLogout, SocialLoginAndRefresh};