import './LinkInsert.css';

import { Row, Col, Form, Badge, Button } from 'react-bootstrap';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from '@util/ajax/AxiosManager';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultInfo = {
    name:'',
    url:'',
    memo:'',
};

const LinkInsert = ()=>{
    const [info, setInfo] = useState({...defaultInfo});
    const [hashtag, setHashtag] = useState('');
    const [hashtagList, setHashtagList] = useState([]);

    const [hashtagInputActive, setHashtagInputActive] = useState(false);
    const [hashtagHoverActive, setHashtagHoverActive] = useState(false);
    const [givenHashtagList, setGivenHashtagList] = useState([]);
    const loadGivenHashtagList = useCallback(()=>{
        axios.get(`${process.env.REACT_APP_AJAX_ROOT_URL}/link/hashtag/`)
        .then(response=>{
            setGivenHashtagList(response.data);
        });
    });
    useEffect(()=>{
        loadGivenHashtagList();
    }, []);

    const changeInfo = e=>{
        const name = e.target.name;
        const value = e.target.value;
        setInfo({
            ...info,
            [name]:value
        });
    };

    const inputHashtag = useCallback(e=>{
        setHashtagInputActive(true);
        setHashtag(e.target.value);
    });
    const addHashtag = useCallback((value)=>{
        if(hashtagList.indexOf(value) < 0) {
            setHashtagList(hashtagList.concat(value));
        }
        setHashtag('');
        setHashtagInputActive(false)
        setHashtagHoverActive(false)
    });
    const processHashtag = useCallback(e=>{
        const keyValue = e.key || e.keyCode;
        if(keyValue == 'Enter' || keyValue == 13) {
            addHashtag(hashtag);
        }
        else if(keyValue == 'Escape' || keyValue == 27) {
            setHashtagInputActive(false);
        }
    });
    useEffect(()=>{
        setHashtag(hashtag.replace(/\s+/, ''));
    }, [hashtag]);

    const clearAllInput = useCallback(()=>{
        setInfo({...defaultInfo});
        setHashtag('');
        setHashtagList([]);
    });

    const checkSendable = useCallback(()=>{
        if(!info.name || !info.url) return false;
        if(hashtagList.length == 0) return false;
        return true;
    });

    const sendData = useCallback(e=>{
        if(!checkSendable()) return;

        const dataObject = {
            ...info,
            hashtags : [...hashtagList]
        };
        
        axios.post(`${process.env.REACT_APP_AJAX_ROOT_URL}/link/website/`, dataObject)
        .then(response=>{
            toast.success('등록이 완료되었습니다', {position:'bottom-right', autoClose:5000, theme:'colored'});
            loadGivenHashtagList();
            clearAllInput();
        })
        .catch(error=>{
            toast.error('등록 오류가 발생했습니다', {position:'bottom-right', autoClose:5000, theme:'colored'});
            console.log(error);
        });
    });

    //filter given hashtag
    const filterGivenHashtagList = useMemo(()=>{
        if(!hashtag) return [...givenHashtagList];
        return givenHashtagList.filter(tag=>tag.name.toLowerCase() === hashtag.toLowerCase());
    }, [hashtag, givenHashtagList]);

    //click
    const clickGivenHashtag = useCallback(e=>{
        addHashtag(e.target.value)
        setHashtagInputActive(false);
    });

    return (<>
        <Row>
            <Col>
                <h1>사이트 등록</h1>
            </Col>
        </Row>
        <Form onSubmit={e=>e.preventDefault()} autoComplete="off">
            <Form.Floating className="mt-4">
                <Form.Control id="name-input" name="name" placeholder="Leave a comment here" value={info.name} className="rounded" onChange={changeInfo}/>
                <Form.Label htmlFor="name-input" className="text-muted">이름
                    <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                </Form.Label>
                <Form.Text className="text-muted">등록할 사이트의 이름을 입력하세요</Form.Text>
            </Form.Floating>

            <Form.Floating className="mt-4">
                <Form.Control id="url-input" name="url" placeholder="Leave a comment here" value={info.url} className="rounded" onChange={changeInfo}/>
                <Form.Label htmlFor="url-input" className="text-muted">주소
                    <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                </Form.Label>
                <Form.Text className="text-muted">등록할 사이트의 주소를 입력하세요</Form.Text>
            </Form.Floating>

            <Form.Floating className="mt-4">
                <Form.Control as="textarea" id="memo-input" name="memo" placeholder="Leave a comment here" value={info.memo} className="rounded" onChange={changeInfo}/>
                <Form.Label htmlFor="memo-input" className="text-muted">설명</Form.Label>
                <Form.Text className="text-muted">등록할 사이트에 대한 설명을 입력하세요</Form.Text>
            </Form.Floating>
            
            <Form.Floating className="mt-4">
                <Form.Control id="hashtag-input" name="hashtag" placeholder="Leave a comment here" className="rounded" value={hashtag} onChange={inputHashtag} onKeyUp={processHashtag}
                    onClick={()=>setHashtagInputActive(true)} onFocus={()=>setHashtagInputActive(true)} onBlur={()=>setHashtagInputActive(false)}/>
                <Form.Label htmlFor="memo-input" className="text-muted mb-2">
                    해시태그
                    <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1 me-1"/>
                    (1개 이상)
                </Form.Label>
                {
                filterGivenHashtagList.length > 0 && 
                <datalist id="hashtag-given" className={`${hashtagInputActive || hashtagHoverActive ? 'active' : ''}`} 
                    onMouseOver={()=>setHashtagHoverActive(true)} onMouseOut={()=>setHashtagHoverActive(false)}>
                    {filterGivenHashtagList.map(tag=><option key={tag.no} value={tag.name} onClick={clickGivenHashtag}>{tag.name}</option>)}
                </datalist>
                }
                <Form.Text>작성 후 엔터를 누르시면 하단에 추가되며 <span className="text-danger">&times;</span>를 눌러 제거할 수 있습니다</Form.Text>
                <br></br>
                {/* Badge */}
                <Form.Text className="text-muted">
                {hashtagList.map(tag=>(
                    <Badge key={tag} bg="light" text="dark" className="mt-2 me-2 border border-dark">
                        {tag}
                        <span className="hashtag-close ms-2" onClick={()=>setHashtagList(hashtagList.filter(item=>item !== tag))}>&times;</span>
                    </Badge>
                ))}
                </Form.Text>
            </Form.Floating>

        </Form>
        
        <Row className="mt-4">
            <Col>
                {
                    checkSendable() ? 
                        <Button variant="primary" className="w-100" onClick={sendData}>정보를 등록합니다</Button>      
                        :
                        <Button variant="primary" className="w-100" disabled>필수 정보를 작성해야 등록할 수 있습니다</Button>      
                }
            </Col>
        </Row>

      <ToastContainer/>
    </>);
};

export default LinkInsert;