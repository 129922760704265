import './Carousel.css';
import './Card.css';

import { useCallback, useState } from 'react';
import { Container, Carousel, Row, Col, Card } from 'react-bootstrap';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';

import carouselImage1 from '@image/carousel/1.png';
import carouselImage2 from '@image/carousel/2.png';
import carouselImage3 from '@image/carousel/3.png';

import PortfolioImage from '@image/card/portfolio.png';
import ExperienceImage from '@image/card/experience.png';
import LibraryImage from '@image/card/library.png';
import RestApiImage from '@image/card/rest-api.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typing3D from '../fragment/threejs/Typing3D';

const ParticleComponent = ({active=true})=>{
    const particlesInit = useCallback(async engine => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        // await console.log(container);
    }, []);

    if(active) {
        return (
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    fpsLimit: 120,
                    interactivity: {
                        events: {
                            // onClick: {
                            //     enable: true,
                            //     mode: "push",
                            // },
                            // onHover: {
                            //     enable: true,
                            //     mode: "repulse",
                            // },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: "#ffffff",
                        },
                        links: {
                            color: "#ffffff",
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 1,
                        },
                        collisions: {
                            enable: true,
                        },
                        move: {
                            directions: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 2,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 800,
                            },
                            value: 80,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 1, max: 2 },
                        },
                    },
                    detectRetina: true,
                }}
            />
        );
    }
    else {
        return <></>
    }
};

const MainCarousel = props=>{
    const [index, setIndex] = useState(0);

    const [active, setActive] = useState([true, false, false]);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
        setActive(active.map((a, i)=>i == selectedIndex));
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={carouselImage1}
            alt="First slide"
            />
            <ParticleComponent active={active[0]}/>
            <Carousel.Caption>
            <h3 className="text-dark">Impossible is Nothing</h3>
            <p className="text-dark">중요한 것은 꺾이지 않는 마음</p>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={carouselImage2}
            alt="Second slide"
            onClick={()=>window.location.href='https://www.youtube.com/@fieryteacher'}
            />
            <ParticleComponent active={active[1]}/>
            <Carousel.Caption>
            <h3 style={ {color:'#FFE669'} }>열혈강사 유튜브 채널 Open</h3>
            <p style={ {color:'#FFE669'} }>개발에 도움되는 영상 업로드 채널</p>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={carouselImage3}
            alt="Third slide"
            onClick={()=>window.location.href='https://docs.sysout.co.kr'}
            />
            <ParticleComponent active={active[2]}/>
            <Carousel.Caption>
            <h3 className="text-black">Gitbook documentation</h3>
            <p className="text-black">
                개발 관련 지식들이 정리된 블로그
            </p>
            </Carousel.Caption>
        </Carousel.Item>
        </Carousel>
    );
};

const CardGrid = props=>{
    const [data, setData] = useState([
        {
            image : PortfolioImage,
            title : '포트폴리오',
            text : '수료생들의 포트폴리오 체험',
            link : '#',
            active: true,
        },
        {
            image : ExperienceImage,
            title : '웹페이지 체험',
            text : '준비중입니다',
            active: false,
        },
        {
            image : LibraryImage,
            title : '라이브러리',
            text : '자체 개발 라이브러리',
            link : '#',
            active: true,
        },
        {
            image : RestApiImage,
            title : 'REST API 샘플',
            text : '준비중입니다',
            active: false,
        },
    ]);
    return (
        <Row xs={1} sm={2} className="g-4">
          {data.map((d, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Img variant="top" src={d.image} />
                <Card.Body>
                    <Card.Title>{d.title}</Card.Title>
                    <Card.Text as='div'>
                        <Row><Col>{d.text}</Col></Row>
                        <Row><Col className="text-end">
                            <a className="card-link" href={d.link} disabled={!d.active}>
                                Go
                                &nbsp;&nbsp;
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right" size='1x'/>
                            </a>
                        </Col></Row>
                    </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      );
};

const MainContent = props=>{
    return (<>
        <Row className="mb-4">
            <Col>
                {/* <MainCarousel style={ {height:'45vh'} }/> */}
                <Typing3D text={[
                    // {value:"W",color:'#c0392b', position:[-10,6,0]},
                    // {value:"e",color:'#e67e22', position:[-4.5,6,0]},
                    // {value:"l",color:'#f1c40f', position:[-1,6,0]},
                    // {value:"c",color:'#27ae60', position:[0.75,6,0]},
                    // {value:"o",color:'#3498db', position:[4.25,6,0]},
                    // {value:"m",color:'#2980b9', position:[7.75,6,0]},
                    // {value:"e",color:'#8e44ad', position:[13,6,0]},
                    // {value:"to",color:'#34495e', position:[18,6,0]},
                    {value:"H",color:'#3498db', position:[-8.5,0,0]},
                    {value:"'",color:'#e74c3c', position:[-4,0,0]},
                    {value:"academy",color:'#bdc3c7', position:[-2.5,0,0]}
                ]} style={ {height:'45vh'} }/>
            </Col>
        </Row>
        <hr></hr>
        <Row className="mt-4">
            <Col>
                <CardGrid/>
            </Col>
        </Row>
    </>);
};

export default MainContent;