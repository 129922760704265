import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { SocialLogin } from "@util/firebase-social/FirebaseManager";
import ContextStore from '@util/store/context';
import { useNavigate } from 'react-router-dom';

const UserLoginPage = props=>{
    const {setLogin, setAuth} = useContext(ContextStore);
    const navigate = useNavigate();

    const login = providerType=>{
        SocialLogin(providerType, result=>{
            //새로고침 처리 하려면 백엔드 필요하므로 나중에 구현
            setAuth(result);
            navigate("/");
        }, error=>{
            setAuth(null);
        });
    };

    return (<>
        <Row>
            <Col className="text-center">
                <h1>
                    로그인 방법 선택
                </h1>
                <p className="text-secondary">
                    소셜 계정으로만 로그인 하실 수 있습니다
                    <br></br>
                    소셜 계정의 <b>이메일</b>, <b>닉네임</b>, <b>프로필</b> 정보를 사용합니다
                </p>
            </Col>
        </Row>
        <Row>
            <Col className="text-center mt-4">
                <Button size="lg" variant="outline-primary" className="rounded" onClick={e=>login('google')}>
                    <FontAwesomeIcon icon="fab fa-google"></FontAwesomeIcon>
                    <br></br>
                    Google
                </Button>
                <Button size="lg" variant="outline-secondary" className="rounded ms-4" onClick={e=>login('github')}>
                    <FontAwesomeIcon icon="fab fa-github"></FontAwesomeIcon>
                    <br></br>
                    Github
                </Button>
            </Col>
        </Row>
    </>);
};

export default UserLoginPage;