import { createContext, useEffect, useState, useMemo } from 'react';
import axios from '@util/ajax/AxiosManager';

const ContextStore = createContext(null);
export default ContextStore;

const ContextCustomProvider = (props)=>{
    //state 사용
    const [auth, setAuth] = useState(null);
    const [login, setLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const admin = useMemo(()=>{
        return auth != null ? auth.admin : false;
    }, [auth]);

    useEffect(()=>{
        //axios 호출시 인터셉트
        axios.interceptors.request.use(function (config) {
            if(config.url.includes('detection')){
                setLoading(true);
            }
            return config;
        }, function (error) {
            return Promise.reject(error);
        });
        //axios 호출 종료시 인터셉트
        axios.interceptors.response.use(function (response) {      
            setLoading(false);
            return response;
        }, function (error) {
            setLoading(false);
            return Promise.reject(error);
        });
    }, []);

    useEffect(()=>setLogin(auth !== null), [auth]);

    const valueObject = {
        auth:auth, 
        setAuth,
        login:login,
        setLogin,
        admin:admin,
        loading:loading,
        setLoading
    };

    return (
        <ContextStore.Provider value={valueObject}>
            {props.children}
        </ContextStore.Provider>
    );
};
export {ContextCustomProvider};
