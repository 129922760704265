import './DevelopInfo.css';

import TypingArea from '@component/fragment/typing/TypingArea';
import { useState, useEffect } from 'react';
import { Row, Col, Accordion, Badge } from 'react-bootstrap';
import axios from '@util/ajax/AxiosManager';

const AccordionListItem = ({info})=>{
    return (
        <Accordion.Item eventKey={info.no}>
            <Accordion.Header>
                <span>{info.name}</span>
                <Badge pill bg="primary" className="ms-4">{info.version}</Badge>
            </Accordion.Header>
            <Accordion.Body>
                <Row className="mt-2">
                    <Col xs={3}>이름</Col>
                    <Col xs={9}>{info.name}</Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={3}>버전</Col>
                    <Col xs={9}>{info.version}</Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={3}>참조</Col>
                    <Col xs={9}>{info.reference && <a target="_blank" href={info.reference} title={info.reference+'로 이동하기'}>{info.reference}</a>}</Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={3}>설명</Col>
                    <Col xs={9}>{info.memo && <p>{info.memo}</p>}</Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    );
};
const AccordionListView = ({list})=>{
    return (
        <Accordion>
            {list.map(info=><AccordionListItem key={info.no} info={info}/>)}
        </Accordion>
      );
};

const DevelopInfo = props=>{
    const [devList, setDevList] = useState([]);

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_AJAX_ROOT_URL}/dev-env/`)
        .then(resp=>{
            setDevList(resp.data);
        });
    }, [ ]);

    return (<>
        <Row className="mt-5">
            <Col>
                <h1>
                    저는 
                    <strong className="introduce ms-4 me-4">
                        <TypingArea data={ ["황인빈 강사", "1인 개발자", "유튜브 열혈강사 운영자", "네이버 익스퍼트 전문가"] } applyColor="true" listColor ={ ["#0984e3", "#6c5ce7", "#d63031", "#00b894"] }/> 
                    </strong>
                    입니다
                </h1>
            </Col>
        </Row>
        <Row className="mt-4"></Row>
        <Row className="mt-4">
            <Col>
                <h2>개발 환경 정보</h2>                                                       
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                <AccordionListView list={devList}/>
            </Col>
        </Row>
    </>);
};
export default DevelopInfo;