import { useMemo, useState } from 'react';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { SunEditorMenuButtonList, SunEditorOptions } from './CustomSunEditorOptions';

const CustomSunEditor = ({
    width='100%',
    height=200,
    value='',
    placeholder='',
    name='content',
    ...etc
})=>{
    return (
        <SunEditor 
            name={name}
            lang="ko" 
            width={width} 
            height={height} 
            placeholder={placeholder} 
            setOptions={SunEditorOptions}
            setDefaultStyle="font-family:BinggraeSamanco; font-size:16px;"
            setContents={value}
            {...etc} 
            />
    );
};

export default CustomSunEditor;