import './MainHeader.css';

import { useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ContextStore from '@util/store/context';
import { SocialLogout, SocialLoginAndRefresh } from '@util/firebase-social/FirebaseManager';

const LoginNavLink = props=>{
    const {login, setLogin, auth, setAuth, admin} = useContext(ContextStore);
    const navigate = useNavigate();

    //새로고침 대응
    useEffect(()=>{
        SocialLoginAndRefresh(member=>{
            setAuth(!member ? null : member);
        }, error=>{
            setAuth(null);
        });
    }, []);

    //로그아웃
    const logout = e=> {
        e.preventDefault();
        setAuth(null);
        SocialLogout(()=>{
            navigate("/");
        }, error=>{
            console.log(error);
            navigate("/");
        });
    };

    //auth !== null 조건이 없으면 useEffect가 실행되는 찰나에 null exception이 발생함
    if(login && auth !== null) {
        return (
            <>
                {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>관리 도구</Tooltip>}> */}
                    {admin && 
                    <Nav.Link className="ms-2 text-end" as={Link} to="/admin">
                        <FontAwesomeIcon icon="fa-solid fa-gear" />
                    </Nav.Link>}
                {/* </OverlayTrigger> */}
                {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>내 정보</Tooltip>}> */}
                    <Nav.Link className="ms-2 text-end" as={Link} to="/member">
                        {/* <FontAwesomeIcon icon="fa-solid fa-user"/> */}
                        <img src={auth.photo} width={16} height={16} className="rounded-circle border-light profile-image"/>
                    </Nav.Link>                 
                {/* </OverlayTrigger> */}
                {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>로그아웃</Tooltip>}> */}
                    <Nav.Link className="ms-2 text-end" as={Link} to="#" onClick={logout}>
                        <FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />
                    </Nav.Link>
                {/* </OverlayTrigger> */}
            </>
        );
    }
    else {
        return (
            <>
                {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>로그인</Tooltip>}> */}
                    <Nav.Link className="ms-2 text-end" as={Link} to="/login/user">
                        <FontAwesomeIcon icon="fa-solid fa-right-to-bracket" />
                    </Nav.Link>
                {/* </OverlayTrigger> */}
            </>
        );
    }
};

const MainHeader = props=>{
    //const navigate = useNavigate();
    const location = useLocation();
    return (
        <Navbar bg="white" expand="lg" className="p-2" fixed='top' collapseOnSelect>
            <Container fluid>
                <Navbar.Brand as={Link} to="/" className="fs-4">
                    <strong>H</strong>
                    <span className="text-danger">'</span>
                    <span className="text-muted">academy</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0" navbarScroll activeKey={location.pathname}>
                        <Nav.Link as={Link} to="/portfolio">Portfolio</Nav.Link>
                        {/* <Nav.Link href="https://docs.sysout.co.kr" disabled={true}>Document</Nav.Link> */}
                        <Nav.Link as={Link} to="/notice">Notice</Nav.Link>
                        {/* <Nav.Link as={Link} to="/quiz">Quiz</Nav.Link> */}
                        <Nav.Link as={Link} to="/link">Link</Nav.Link>
                    </Nav>
                    <Nav className="d-flex">
                        <LoginNavLink/>
                    </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
    );
};

export default MainHeader;