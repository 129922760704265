import './NoticeBoardList.css';

import { Accordion, Button, Card, Col, Row, Table } from "react-bootstrap";
import Jumbotron from '@component/fragment/Jumbotron';
import { useEffect, useState } from "react";
import axios from '@util/ajax/AxiosManager';

const NoticeBoardList = props=>{
    const [noticeData, setNoticeData] = useState([]);
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_AJAX_ROOT_URL}/notice/p/1/`)
        .then(response=>{
            setNoticeData(response.data);
        })
        .catch(error=>{});
    }, []);

    return (<>
        <Row>
            <Col>
                <Jumbotron>
                    <Jumbotron.Title>공지사항</Jumbotron.Title>
                </Jumbotron>
            </Col>
        </Row>

        <Row className="mt-4">
            <Col>
                <Table>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th width="50%">제목</th>
                            <th>작성일</th>
                        </tr>
                    </thead>
                    <tbody>
                        {noticeData.content && noticeData.content.length > 0 ? 
                        noticeData.content.map(notice=>(
                        <tr key={notice.no}>
                            <td>{notice.no}</td>
                            <td>
                                <Accordion className="custom-table-accordion">
                                    <Accordion.Item eventKey={notice.no}>
                                        <Accordion.Header>{notice.title}</Accordion.Header>
                                        <Accordion.Body>
                                            <div dangerouslySetInnerHTML={ {__html:notice.content} }></div>    
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </td>
                            <td>YYYY.MM.DD</td>
                        </tr>
                        )) 
                        :
                        <tr><td colSpan={3} className="text-center">등록된 공지사항이 없습니다</td></tr>}
                    </tbody>
                </Table>
            </Col>
        </Row>
    </>);
};
export default NoticeBoardList;