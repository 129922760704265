//비회원 전용 라우터
import { useContext } from 'react';
import { Navigate } from 'react-router';
import ContextStore from '@util/store/context';

const PublicRoute = ({children, restricted=false})=>{
    const {login} = useContext(ContextStore);

    if(login && restricted) 
        return <Navigate to="/"/>
    else 
        return <>{children}</>
};

export default PublicRoute;