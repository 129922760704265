import './App.css';
import '@component/fragment/loader/LoadingScreen.css';

import {Routes, Route} from 'react-router';

import {Container, Row, Col} from 'react-bootstrap';

import PublicRoute from '@util/router/PublicRoute';
import MemberRoute from '@util/router/MemberRoute';

import MainHeader from '@component/template/MainHeader';
import MainFooter from '@component/template/MainFooter';

import MainContent from '@component/screen/MainContent';
import PortfolioList from '@component/screen/portfolio/PortfolioList';
import QuizList from '@component/screen/quiz/QuizList';
import LinkList from '@component/screen/link/LinkList';
import NoticeBoardList from '@component/screen/notice/NoticeBoardList';
import DevelopInfo from '@component/screen/develop/DevelopInfo';
import MemberLoginPage from '@component/screen/member/MemberLoginPage';
import PageNotFound from '@component/screen/error/PageNotFound';
import AccessDenied from '@component/screen/error/AccessDenied';

import LoadingScreen from '@component/fragment/loader/LoadingScreen';

import useTitle from '@util/title/useTitle';
import { useEffect, useContext } from 'react';
import MemberDetailPage from '@component/screen/member/MemberDetailPage';
import ContextStore from '@util/store/context';
import AdminHome from '@component/screen/admin/AdminHome';

const App = ()=>{
  const changeTitle = useTitle();
  useEffect(()=>changeTitle("H'academy"), []);

  const {loading} = useContext(ContextStore);

  return (
    <>
      <MainHeader/>
      <Container fluid className="mt-5 mb-5">
        <Row className="mt-4"></Row>
        <Row className="mt-4"></Row>
        <Row className="mt-4"></Row>
        <Row>
          <Col lg={ {span:10, offset:1} }> 
            <Routes>
              <Route path='/' element={<PublicRoute><MainContent/></PublicRoute>}/>
              <Route path='/portfolio' element={<PublicRoute><PortfolioList/></PublicRoute>}/>
              <Route path='/quiz' element={<QuizList/>}/>
              <Route path='/link' element={<PublicRoute><LinkList/></PublicRoute>}/>
              <Route path='/notice' element={<PublicRoute><NoticeBoardList/></PublicRoute>}/>
              <Route path='/developer' element={<PublicRoute><DevelopInfo/></PublicRoute>}/>
              <Route path='/login/user' element={<PublicRoute restricted={true}><MemberLoginPage/></PublicRoute>}/>
              <Route path='/member' element={<MemberRoute><MemberDetailPage/></MemberRoute>}></Route>
              <Route path="/admin/*" element={<MemberRoute needAdmin={true}><AdminHome/></MemberRoute>}/>
              <Route path="/access-denied" element={<AccessDenied/>}/>
              <Route path='*' element={<PublicRoute><PageNotFound/></PublicRoute>}/>
            </Routes> 
          </Col>
        </Row>
      </Container>
      <MainFooter/>

      <LoadingScreen loading={loading}/>

    </>
  );
}

export default App;
