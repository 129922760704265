import { useState, useEffect } from 'react';

const useTitle = ()=>{
    const [title, setTitle] = useState('');

    const updateTitle = ()=>{
        document.title = title;
    };

    useEffect(updateTitle, [title]);

    return setTitle;
};

export default useTitle;