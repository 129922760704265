import * as THREE from 'three';
import { extend } from '@react-three/fiber';
import { Bounds, ContactShadows, Float, OrbitControls, PerspectiveCamera, Plane, Reflector, Stage } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Suspense, useMemo } from 'react';

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

import data from '@compai/font-inter/data/typefaces/normal-800.json';
import { Fog, Material } from 'three';

extend({ TextGeometry });

const font = new FontLoader().parse(data);

const Text = ({children, scale=0.2, height=10, color='#000000', ...otherProps})=>{
    const config = useMemo(()=>({
        font,
        size:20,
        height,
        curveSegment:32,
        bevelEnabled:true,
        bevelThickness:4,
        bevelSize:1,
        bevelOffset:0,
        bevelSegments:16
    }), [height]);
    return (
        <mesh scale={scale} {...otherProps}>
            <textGeometry args={ [children, config] }/>
            <meshPhysicalMaterial color={color} clearcoat={1} clearcoatRoughness={0.1} metalness={0} roughness={0.1} />
        </mesh>
    );
};

const Typing3D = ({
    text = [{value:"Hello", color:'black', position:[-5, 0, 0]}], 
    height=0.25,
    ...otherProps
})=>{
    // const splitText = useMemo(()=>text.split(/\\n/g), [text]);
    return (
        <div id="canvas-container" {...otherProps}>
            <Suspense fallback={null}>
                <Canvas>
                    {/* <color attach="background" args={['#000000']}/> */}
                    <OrbitControls autoRotate={true} autoRotateSpeed={-3} enablePan={false} minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2}/>
                    <directionalLight position={ [0, 0, 1] } intensity={0.125}></directionalLight>
                    <pointLight position={ [0, 100, 90] } intensity={3}></pointLight>
                    <Stage intensity={0} environment="city" adjustCamera>
                        <Bounds fit observe clip>
                            {text.map((t, i)=>(
                                <Text key={i} position={t.position} height={height} color={t.color}>{t.value}</Text>
                            ))}
                        </Bounds>
                        {/* <Plane args={ [100, 100] } color='blue' rotation={ [-Math.PI/2, 0, 0]} position={ [0,-10,0] }>
                            <meshPhongMaterial color={0xffffff}/>
                        </Plane> */}
                    </Stage>
                </Canvas>
            </Suspense>
        </div>
    );
};

export default Typing3D;