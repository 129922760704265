import { BeatLoader, ClockLoader } from 'react-spinners';

const LoadingScreen = ({loading})=>{
    const check = loading ? loading : false;
    return (
        check &&
        <div className='axios-loading' style={{display:loading===true?'flex':'none'}}>
            <div className='axios-loading-indicator'>
                {/* <BeatLoader color={"white"} loading={loading}  size={40} /> */}
                <ClockLoader/>
            </div>
        </div>
    );
};

export default LoadingScreen;