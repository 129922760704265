import './MainFooter.css';
import { Container, Row, Col, Stack, OverlayTrigger, Tooltip, NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const MainFooter = props=>{
    return (<>
        <hr></hr>
        <Container fluid className="mt-4 mb-4">
            <Row>
                <Col md={ {span:4,offset:1} } className="mb-4">
                    <Row>
                        <Col>
                            <span className="hacademy-footer-brand">
                                <span className="strong">H</span>
                                <span className="dim">wang</span>
                                <span className="text-danger">'</span>
                                <span className="dim">s</span>
                                <span className="dim">&nbsp;</span>
                                <span className="text-muted">academy</span>
                            </span>
                        </Col>
                    </Row>
                    <span className="text-secondary"><strong>황인빈 강사</strong>와 <strong>수강생</strong>들의 공간</span>
                    <Row className="mt-4">
                        <Col>
                            <Stack direction='horizontal' gap={3}>
                                <OverlayTrigger placement="top" overlay={<Tooltip>Youtube</Tooltip>}>
                                    <NavLink target="_blank" href="https://www.youtube.com/channel/UCdllyP_VgK-PY7VJRWOkOKQ">
                                        <FontAwesomeIcon icon="fab fa-youtube" className="fa-2x text-danger"/>
                                    </NavLink>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={<Tooltip>Github</Tooltip>}>
                                    <NavLink target="_blank" href="https://github.com/hiphop5782">
                                        <FontAwesomeIcon icon="fab fa-github" className="fa-2x text-secondary"/>
                                    </NavLink>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={<Tooltip>Gitbook</Tooltip>}>
                                    <NavLink target="_blank" href="https://docs.sysout.co.kr">
                                        <FontAwesomeIcon icon="fa-solid fa-book" className="fa-gitbook"/>
                                    </NavLink>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={<Tooltip>Naver Expert</Tooltip>}>
                                    <NavLink target="_blank" href="https://m.expert.naver.com/expert/profile/home?storeId=100034515">
                                        <FontAwesomeIcon icon="fa-solid fa-n" className="fa-naver"/>
                                    </NavLink>
                                </OverlayTrigger>
                            </Stack>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <p>홈페이지 자료에 대한 무단 도용을 금지합니다</p>
                    <Link to="/developer" type="button" className="btn btn-outline-secondary rounded">개발자 및 개발 환경 보기</Link>
                </Col>
            </Row>
        </Container>
    </>);
};

export default MainFooter;