import './AdminHome.css';

import { Col, Row } from "react-bootstrap";
import Jumbotron from '@component/fragment/Jumbotron';
import { Link, Route, Routes } from 'react-router-dom';
import LinkInsert from '@component/screen/admin/link/LinkInsert';
import DevelopInfoManageScreen from '@component/screen/admin/develop/DevelopInfoManageScreen';
import PageNotFound from '@component/screen/error/PageNotFound';
import PortfolioInsert from '@component/screen/admin/portfolio/PortfolioInsert';
import NoticeBoardInsert from '@component/screen/admin/notice/NoticeBoardInsert';
import PortfolioDeploy from '@component/screen/admin/portfolio/PortfolioDeploy';
import AdminMainContent from '@component/screen/admin/AdminMainContent';

const AdminHome = ()=>{
    return (<>
        <Row className="mb-4">
            <Col>
                <Jumbotron>
                    <Jumbotron.Title>관리자 페이지</Jumbotron.Title>
                    <Jumbotron.Content>
                        <div className="admin-navbar">
                            <Link to="./link/insert">#웹사이트등록</Link>
                            <Link to="./develop">#개발환경관리</Link>
                            <Link to="./portfolio">#포트폴리오등록</Link>
                            <Link to="./notice">#공지사항작성</Link>
                            <Link to="./deploy">#프로젝트배포설정</Link>
                        </div>
                    </Jumbotron.Content>
                </Jumbotron>
            </Col>
        </Row>
        <Routes>
            <Route path='/' element={<AdminMainContent/>}/>
            <Route path='/link/insert' element={<LinkInsert/>}/>
            <Route path='/develop' element={<DevelopInfoManageScreen/>}/>
            <Route path='/portfolio' element={<PortfolioInsert/>}/>
            <Route path='/notice' element={<NoticeBoardInsert/>}/>
            <Route path='/deploy' element={<PortfolioDeploy/>}/>
            <Route path='*' element={<PageNotFound/>}/>
        </Routes>
    </>)
};

export default AdminHome;