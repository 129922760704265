import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomSunEditor from "@component/fragment/rich-text-editor/CustomSunEditor";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from '@util/ajax/AxiosManager';
import { toast, ToastContainer } from "react-toastify";

const NoticeBoardList = ()=>{
    const [data, setData] = useState({
        title:'',
        content:'',
    });

    const changeTitle = e=>{
        setData({
            ...data,
            [e.target.name] : e.target.value
        });
    };

    const changeContent = value=>{
        setData({...data, ['content']:value});
    };

    const clearData = ()=>{
        setData({
            title:'',
            content:'',
        });
    };

    const sendData = ()=>{
        if(!data.title || !data.content) {
            window.alert("미 작성된 항목이 있습니다");
            return;
        }

        axios.post(`${process.env.REACT_APP_AJAX_ROOT_URL}/notice/`, data)
        .then(response=>{
            toast.success('등록이 완료되었습니다', {position:'bottom-right', autoClose:5000, theme:'colored'});
            clearData();
        })
        .catch(error=>{
            toast.error('등록 오류가 발생했습니다', {position:'bottom-right', autoClose:5000, theme:'colored'});
        });
    };

    return (<>
        <Row>
            <Col>
                <h1>공지 작성</h1>
            </Col>
        </Row>

        <Form onSubmit={e=>e.preventDefault()} autoComplete="off">
            {/* 공지글 제목 */}
            <Form.Floating className="mt-4">
                <Form.Control id="title-input" name="title" placeholder="comment" className="rounded" value={data.title} onChange={changeTitle}/>
                <Form.Label htmlFor="title-input" className="text-muted">
                    공지 게시글 제목
                    <FontAwesomeIcon icon="fa-solid fa-asterisk" className="text-danger ms-1"/>
                </Form.Label>
            </Form.Floating>

            <Form.Group className="mt-4">
                <CustomSunEditor placeholder="공지 게시글 내용" height="250" value={data.content} onChange={changeContent}/>
            </Form.Group>
        </Form>

        <Row className="mt-4">
            <Col>
                <Button variant="success" className="w-100" onClick={sendData}>
                    <FontAwesomeIcon icon="fa fa-pen" className="me-2"/>
                    공지사항 작성
                </Button>
            </Col>
        </Row>

        <ToastContainer/>
    </>);
};

export default NoticeBoardList;