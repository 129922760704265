import { useState, useEffect } from 'react';
import axios from '@util/ajax/AxiosManager';
import { Col, Row } from 'react-bootstrap';
const AdminMainContent = ()=>{
    const [text, setText] = useState('');
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_AJAX_ROOT_URL}/deploy/status`)
        .then(response=>{
            setText(response.data);
        });
    }, []);
    return (<>
        <Row className="mt-4">
            <Col>
                {/* <div dangerouslySetInnerHTML={ {__html:text} }></div> */}
                <pre style={ {'font-size':24} }>{text}</pre>
            </Col>
        </Row>
    </>)
};

export default AdminMainContent;