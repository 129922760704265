// menu button list
const SunEditorMenuButtonList = [
    ['formatBlock'],
    ['font','fontSize'],
    ['fontColor', 'hiliteColor'],
    ['align','list', 'table'],
    ['link', 'image', 'video'],
];

const fontList = ['BinggraeSamanco', 'Binggrae', 'Verdana', 'Consolas', 'Sans-serif', 'serif', 'monospace'];

const colorList = [
    '#55efc4', '#81ecec', '#74b9ff', '#a29bfe', '#dfe6e9', '#2ecc71', '#3498db', '#9b59b6', '#34495e',
    '#00b894', '#00cec9', '#0984e3', '#6c5ce7', '#b2bec3', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50',
    '#ffeaa7', '#fab1a0', '#ff7675', '#fd79a8', '#636e72', '#e67e22', '#e74c3c', '#ecf0f1', '#95a5a6',
    '#fdcb6e', '#e17055', '#d63031', '#e84393', '#2d3436', '#d35400', '#c0392b', '#bdc3c7', '#7f8c8d',
];

const SunEditorOptions = {
    buttonList:SunEditorMenuButtonList,
    font:fontList,
    colorList:colorList,
}

export {SunEditorOptions}