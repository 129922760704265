import './PortfolioList.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Jumbotron from "@component/fragment/Jumbotron";
import { useEffect, useState } from "react";
import { Accordion, Badge, Button, Col, ListGroup, Row, Stack, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from '@util/ajax/AxiosManager';

const Logo = ({portfolio})=>{
    if(!portfolio.logo) {
        return null;
    }
    return (
        <Row>
            <Col>
                <Image src={`${process.env.REACT_APP_AJAX_ROOT_URL}/portfolio/logo/${portfolio.no}`} height={50}/>
            </Col>
        </Row>
    );
};

const PortfolioListItem = ({portfolio})=>{
    const [available, setAvailable] = useState(false);

    const openLinkToNewWindow = e=>{
        e.preventDefault();
        window.open(e.target.href);
    };

    useEffect(()=>{
        if(!portfolio) return;
        axios.get(`https://www.sysout.co.kr/${portfolio.url}/`)
        .then(response=>setAvailable(true))
        .catch(error=>setAvailable(false));
    }, [portfolio]);

    return (
        <Accordion.Item eventKey={portfolio.no}>
            <Accordion.Header>
                <div className="w-75">
                    <span className="name">{portfolio.name}</span>
                </div>
                <div className="w-25 d-flex flex-row-reverse pe-4">
                    {available ? 
                        <Badge bg="success">접속가능</Badge>
                        :<Badge bg="danger">접속불가</Badge>
                    }
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Logo portfolio={portfolio}></Logo>
                <Row className="mt-4">
                    <Col xs={2}>이름</Col>
                    <Col xs={10}>{portfolio.name}</Col>
                </Row>
                <Row className="mt-4">
                    <Col xs={2}>주소</Col>
                    <Col xs={10}>
                        <a href={`https://www.sysout.co.kr/${portfolio.url}`} onClick={openLinkToNewWindow}>{`https://www.sysout.co.kr/${portfolio.url}`}</a>
                    </Col>
                </Row>
                <hr/>
                <Row className="mt-4">
                    <Col xs={2}>인원</Col>
                    <Col xs={10}>
                        총 {portfolio.collaborators.length}명
                        <ListGroup className="mt-2">
                        {portfolio.collaborators.map(collaborator=>(
                            <ListGroup.Item key={collaborator.no} className="d-flex justify-content-between align-items-baseline">
                                <span>
                                    {collaborator.name}
                                    {collaborator.captain && <Badge className="ms-3">조장</Badge>}
                                </span>
                                {collaborator.url && 
                                    <a href={collaborator.url} onClick={openLinkToNewWindow}>
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" className="ms-2"/>
                                    </a>
                                }
                            </ListGroup.Item>
                        ))}
                        </ListGroup>
                    </Col>
                </Row>
                <hr/>
                <Row className="mt-4">
                    <Col xs={2}>참조</Col>
                    <Col xs={10}>
                        <ListGroup>
                        {portfolio.references.length > 0 ? portfolio.references.map(reference=>(
                            <ListGroup.Item key={reference.no} className="d-flex justify-content-between align-items-baseline">
                                <span>{reference.name}</span>
                                {reference.url && 
                                    <a href={reference.url} onClick={openLinkToNewWindow}>
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" className="ms-2"/>
                                    </a>
                                }
                            </ListGroup.Item>
                        )) : '없음'}
                        </ListGroup>
                    </Col>
                </Row>
                <hr/>
                <Row className="mt-4">
                    <Col xs={2}>설계</Col>
                    <Col xs={10}>
                        <ListGroup>
                        {portfolio.storages.length > 0 ? portfolio.storages.map(storage=>(
                            <ListGroup.Item key={storage.no} className="d-flex justify-content-between align-items-baseline">
                                <span>{storage.type}</span>
                                {storage.url && 
                                    <a href={storage.url} onClick={openLinkToNewWindow}>
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" className="ms-2"/>
                                    </a>
                                }
                            </ListGroup.Item>
                        )) : '없음'}
                        </ListGroup>
                    </Col>
                </Row>
                <hr/>
                <Row className="mt-4">
                    <Col xs={2}>계정</Col>
                    <Col xs={10}>
                        <ListGroup>
                        {portfolio.accounts.length > 0 ? portfolio.accounts.map(account=>(
                            <ListGroup.Item key={account.no} className="d-flex justify-content-between align-items-baseline">
                                <Row>
                                    <Col xs={12}>
                                        <Badge bg='dark' text='light' className="mono me-2">ID</Badge>    
                                        <span>{account.username}</span>
                                    </Col>
                                    <Col xs={12}>
                                        <Badge bg='dark' text='light' className="mono me-2">PW</Badge>
                                        <span>{account.password}</span>
                                    </Col>
                                </Row>
                                <Badge>{account.type}</Badge>
                            </ListGroup.Item>
                        )) : '없음'}
                        </ListGroup>
                    </Col>
                </Row>
                <hr/>
                <Row className="mt-4">
                    <Col xs={2}>설명</Col>
                    <Col xs={10}>
                        <div dangerouslySetInnerHTML={ {__html:portfolio.explain} }></div>
                    </Col>
                </Row>
                <hr/>
                <Row className="mt-4">
                    <Col xs={12} className="text-end">
                        <Button variant="outline-primary" className="rounded">
                            <FontAwesomeIcon icon="fa-solid fa-thumbs-up" />
                        </Button>
                        <Button variant="outline-secondary" className="rounded ms-2">
                            <FontAwesomeIcon icon="fa-solid fa-star" />
                        </Button>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    );
};
const PortfolioListView = ({portfolioList})=>{
    if(!portfolioList || portfolioList.length == 0) {
        return <h2>등록된 포트폴리오가 없습니다</h2>
    }
    else {
        return (
            <Accordion>
                {portfolioList.map(portfolio=><PortfolioListItem key={portfolio.no} portfolio={portfolio}/>)}
            </Accordion>
        );
    }
};

const PortfolioList = props=>{
    const [portfolioList, setPortfolioList] = useState([]);
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_AJAX_ROOT_URL}/portfolio/`)
        .then(response=>{
            setPortfolioList(response.data);
        })
        .catch(error=>{});
    }, []);

    return (<>
        <Row>
            <Col>
                <Jumbotron>
                    <Jumbotron.Title>수료생 포트폴리오</Jumbotron.Title>
                    <Jumbotron.Content>무단 
                        &nbsp;
                        <Jumbotron.Highlight variant="danger">복제</Jumbotron.Highlight>
                        &nbsp;를 금지하며 사용 시&nbsp;
                        <Jumbotron.Highlight variant="danger">출처</Jumbotron.Highlight>
                        를 꼭 밝혀 주시기 바랍니다</Jumbotron.Content>
                </Jumbotron>
            </Col>
        </Row>

        <Row className="mt-4">
            <Col>
                <PortfolioListView portfolioList={portfolioList}></PortfolioListView>
            </Col>
        </Row>
    </>);
};
export default PortfolioList;