import './LinkList.css';

import Jumbotron from '@component/fragment/Jumbotron';
import { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Accordion, Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from '@util/ajax/AxiosManager';
import ContextStore from '@util/store/context';

const AccordionListItem = ({website, setHashtag})=>{
    const { login } = useContext(ContextStore);
    const diff = website.beforeRank - website.currentRank;
    const calculateDiffString = (()=>{
        if(website.beforeRank === 0) {
            return 'new';
        }
        if(diff > 0) return '+' + diff;
        else if(diff === 0) return '-';
        else return diff;
    });

    const moveToWebsite = useCallback((website, e)=>{
        e.preventDefault();

        axios.patch(`${process.env.REACT_APP_AJAX_ROOT_URL}/link/website/move/${website.no}`)
        .then(response=>{
            //open new tab
            window.open(website.url);
        })
    });

    return (
        <Accordion.Item eventKey={website.no}>
            <Accordion.Header>
                <div className="w-25">
                    <span className={`rank ${website.currentRank===1 ? 'rank-1':''} ${website.currentRank===2 ? 'rank-2':''} ${website.currentRank===3 ? 'rank-3':''}`}>{website.currentRank}</span>
                    <sub className={`change ${diff > 0 ? 'up' : ''} ${diff < 0 ? 'down':''} ${website.beforeRank === 0 ? 'new':''}`}>
                        {calculateDiffString()}
                    </sub>
                </div>
                <div className="w-75">
                    <span className="name">{website.name}</span>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <Row className="mt-2">
                    <Col xs={3}>순위</Col>
                    <Col xs={9}>
                        {website.currentRank} 
                        <sub className={`${diff > 0 ? 'up' : ''} ${diff < 0 ? 'down':''} ${website.beforeRank === 0 ? 'new':''}`}>
                            {calculateDiffString()}
                        </sub>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={3}>이름</Col>
                    <Col xs={9}>{website.name}</Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={3}>분류</Col>
                    <Col xs={9}>
                        {website.hashtags.map(tag=>(<Link key={tag.hashtag.no} className="me-2" onClick={e=>setHashtag(tag.hashtag.name)}>#{tag.hashtag.name}</Link>))}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={3}>홈페이지</Col>
                    <Col xs={9}>
                        <a href={website.url} title={website.url+'로 이동하기'} onClick={e=>moveToWebsite(website, e)}>{website.url}</a>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={3}>설명</Col>
                    <Col xs={9}>{website.memo}</Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={3}>랭킹포인트</Col>
                    <Col xs={9}>{website.point}</Col>
                </Row>
                {login &&
                <Row className="mt-2">
                    <Col xs={12} className="text-end">
                        <Button variant="outline-primary" className="rounded">
                            <FontAwesomeIcon icon="fa-solid fa-thumbs-up" />
                            <span className="ms-2">{website.good}</span>
                        </Button>
                        <Button variant="outline-secondary" className="rounded ms-2">
                            <FontAwesomeIcon icon="fa-solid fa-star" />
                        </Button>
                    </Col>
                </Row>}
            </Accordion.Body>
        </Accordion.Item>
    );
};

const AccordionListView = ({websiteList, setHashtag})=>{
    if(websiteList.length > 0){
        return (
            <Accordion>
                {websiteList.map(website=><AccordionListItem key={website.no} website={website} setHashtag={setHashtag}/>)}
            </Accordion>    
        );
    }
    else {
        return <div className="text-center text-muted"><h2>데이터가 존재하지 않습니다</h2></div>
    }
};

const LinkList = props=>{
    const [hashtag, setHashtag] = useState('');
    const [hashtagList, setHashtagList] = useState([]);
    const [websiteList, setWebsiteList] = useState([]);

    //load data
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_AJAX_ROOT_URL}/link/hashtag-and-websites/`)
        .then(response=>{
            const list = response.data;
            setHashtag(list[0].name);
            setHashtagList(list);
        })
        .catch(error=>{});
    }, []);

    useEffect(()=>{
        if(!hashtag) return;
        
        axios.get(`${process.env.REACT_APP_AJAX_ROOT_URL}/link/hashtag/${hashtag}`)
        .then(response=>{
            const list = response.data;
            setWebsiteList(list);
        });
    }, [hashtag]);

    return (<>
        <Row>
            <Col>
                <Jumbotron>
                    <Jumbotron.Title>개발에 도움되는 웹사이트 모음</Jumbotron.Title>
                    {
                        websiteList.length > 0 ? 
                        <Jumbotron.Content>
                            <Jumbotron.Highlight>{hashtag}</Jumbotron.Highlight> 분야의 웹 사이트 목록을 보고 있습니다
                        </Jumbotron.Content> : <>&nbsp;</>
                    }
                </Jumbotron>
            </Col>
        </Row>
        <Row className="mt-2">
            <Col>
                {hashtagList.map(
                    tag=>{
                        return tag.name===hashtag ? 
                            <Link key={tag.no} to="/link" className="me-2 text-danger">#{tag.name}({tag.websites.length})</Link>
                            : <Link key={tag.no} to="/link" className="me-2" onClick={e=>setHashtag(tag.name)}>#{tag.name}({tag.websites.length})</Link>
                        }
                    )
                }
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                <AccordionListView websiteList={websiteList} setHashtag={setHashtag}></AccordionListView>
            </Col>
        </Row>
    </>);
};
export default LinkList;