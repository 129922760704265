import './DevelopInfoManageScreen.css';

import { Col, FloatingLabel, Row, Form, Button } from "react-bootstrap";
import { useState, useEffect, useCallback } from 'react';
import axios from '@util/ajax/AxiosManager';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DevelopInfoManageScreen = ()=>{
    const [envList, setEnvList] = useState([]);
    const [backup, setBackup] = useState([]);

    const loadEnvList = useCallback(()=>{
        axios.get(`${process.env.REACT_APP_AJAX_ROOT_URL}/dev-env/`)
        .then(response=>{
            const list = response.data;
            setEnvList(list);
            setBackup(list);
        })
    });
    useEffect(()=>{
        loadEnvList();
    }, []);

    //event
    const inputProcessor = (env, e)=>{
        const name = e.target.name;
        const value = e.target.value;
        setEnvList(envList.map(item=>{
            if(item.no === env.no) {
                return {...env, [name]:value};
            }
            return item;
        }));
    };
    const resetProcessor = e=>{
        const choice = window.confirm("작성중인 정보를 초기화 하시겠습니까?");
        if(choice) {
            setEnvList([...backup]);
        }
    };
    const addProcessor = e=>{
        const no = envList.length > 0 ? envList[envList.length-1].no + 1 : 1;
        setEnvList(envList.concat({
            no : no,
            name : '',
            version : '',
            memo : ''
        }))
    };
    const saveProcessor = e=>{
        const filterList = envList.filter(item=>{
            if(!item.name) return false;
            return true;
        });

        if(filterList.length !== envList.length) {
            window.alert("미작성된 항목이 있습니다");
            return;
        }

        const choice = window.confirm("정보를 설정하시겠습니까?");
        if(!choice) return;

        axios.post(`${process.env.REACT_APP_AJAX_ROOT_URL}/dev-env/multiple`, envList)
        .then(response=>{
            toast.success('등록이 완료되었습니다', {position:'bottom-right', autoClose:5000, theme:'colored'});
            loadEnvList();
        })
        .catch(error=>{
            toast.error('등록 오류가 발생했습니다', {position:'bottom-right', autoClose:5000, theme:'colored'});
        });
    };
    const deleteProcessor = env=>{
        const choice = window.confirm("해당 항목을 삭제하시겠습니까?");
        if(!choice) return;

        setEnvList(envList.filter(item=>item.no !== env.no));
    };

    return (<>
        <Row>
            <Col>
                <h1>개발 환경 관리</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <table className="table table-bordered dev-env-display">
                <thead className="bg-dark text-light text-center">
                    <tr>
                        <th rowSpan={2} className="align-middle">번호</th>
                        <th>이름</th>
                        <th>버전</th>
                        <th>주소</th>
                    </tr>
                    <tr>
                        <th colSpan={3}>설명</th>
                    </tr>
                </thead>
                {envList.map(env=>(
                <tbody key={env.no}>
                    <tr>
                        <td rowSpan="2" className="align-middle text-center">
                            {env.no !== 0 ? env.no : null}
                            <br/>
                            <FontAwesomeIcon icon="fa-solid fa-xmark" onClick={e=>deleteProcessor(env, e)}/>
                        </td>
                        <td>
                            <FloatingLabel label="이름">
                                <Form.Control type="text" name="name" placeholder="이름" value={env.name} onChange={e=>inputProcessor(env, e)}/>
                            </FloatingLabel>
                        </td>
                        <td>
                            <FloatingLabel label="버전 (x.y.z)">
                                <Form.Control type="text" name="version" placeholder="버전 (x.y.z)" value={env.version} onChange={e=>inputProcessor(env, e)}/>
                            </FloatingLabel>
                        </td>
                        <td>
                            <FloatingLabel label="홈페이지 주소">
                                <Form.Control type="url" name="reference" placeholder="홈페이지 주소" value={env.reference} onChange={e=>inputProcessor(env, e)}/>
                            </FloatingLabel>
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={3}>
                            <FloatingLabel label="설명">
                                <Form.Control type="text" name="memo" placeholder="설명" value={env.memo} onChange={e=>inputProcessor(env, e)}/>
                            </FloatingLabel>
                        </th>
                    </tr>
                </tbody>
                ))}
            </table>
            </Col>
        </Row>
        <Row>
            <Col className="text-end">
                <Button variant="danger" className="ms-2" onClick={resetProcessor}>
                    <FontAwesomeIcon icon="fa-solid fa-refresh" className="me-2"></FontAwesomeIcon>
                    초기화
                </Button>
                <Button variant="secondary" className="ms-2" onClick={addProcessor}>
                    <FontAwesomeIcon icon="fa-solid fa-circle-plus" className="me-2"></FontAwesomeIcon>
                    항목 추가
                </Button>
                <Button variant="primary" className="ms-2" onClick={saveProcessor}>
                    <FontAwesomeIcon icon="fa-solid fa-floppy-disk" className="me-2"></FontAwesomeIcon>
                    전체 저장
                </Button>
            </Col>
        </Row>

        <ToastContainer/>
    </>);
};

export default DevelopInfoManageScreen;