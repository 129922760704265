import {library} from '@fortawesome/fontawesome-svg-core';

import {
    faGear, 
    faRightToBracket, 
    faRightFromBracket, 
    faMagnifyingGlass,
    faUser,
    faN,
    faS,
    faThumbsUp,
    faThumbsDown,
    faStar,
    faBook,
    faArrowRight,
    faArrowUpRightFromSquare,
    faAsterisk,
    faPlus,
    faFloppyDisk,
    faXmark,
    faRefresh,
    faCirclePlus,
    faEdit,
    faPen,
    faSync,
    faHandPointer,
    faFile,
} from '@fortawesome/free-solid-svg-icons';

import { fab } from '@fortawesome/free-brands-svg-icons';

import './font-awesome-custom.css';

library.add(
    faGear, 
    faRightToBracket, 
    faRightFromBracket, 
    faMagnifyingGlass,
    faUser,
    faN, 
    faS,
    fab,
    faThumbsUp,
    faThumbsDown,
    faStar,
    faBook,
    faArrowRight,
    faArrowUpRightFromSquare,
    faAsterisk,
    faPlus,
    faFloppyDisk,
    faXmark,
    faRefresh,
    faCirclePlus,
    faEdit,
    faPen,
    faSync,
    faHandPointer,
    faFile,
);

export default library;