import { Col, Row } from "react-bootstrap";
import Jumbotron from '@component/fragment/Jumbotron';

const PageNotFound = props=>{
    return (<>
        <Row>
            <Col>
                <Jumbotron bg={null} text={'dark'}>
                    <Jumbotron.Title>404 Page not found</Jumbotron.Title>
                    <Jumbotron.Content><span className="text-muted">해당 주소에 대한 내용을 찾을 수 없습니다</span></Jumbotron.Content>
                </Jumbotron>
            </Col>
        </Row>
    </>);
};

export default PageNotFound;