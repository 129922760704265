import './MemberDetailPage.css';

import { Card, Col, Row } from 'react-bootstrap';
import { useContext } from 'react';
import ContextStore from '@util/store/context';
import moment from '@util/time/MomentManager';

const MemberDetailPage = ()=>{
    const {auth, login} = useContext(ContextStore);
    if(login) {
        return (<>
            <Row>
                <Col xs={3}>
                    <img src={auth.photo} className="rounded-circle w-100"></img>
                </Col>
                <Col xs={9}>
                    <Row>
                        <Col>
                            <h2>{auth.nickname} 님의 프로필</h2>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={3}><b className="text-primary">E-mail</b></Col>
                        <Col md={9}>{auth.email}</Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={3}><b className="text-primary">Provider</b></Col>
                        <Col md={9}>{auth.provider}</Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={3}><b className="text-primary">최초 로그인</b></Col>
                        <Col md={9}>
                            {moment(auth.ctime).fromNow()}
                            ({moment(auth.ctime).format('YYYY-MM-DD HH:mm:ss')})
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={3}><b className="text-primary">최종 로그인</b></Col>
                        <Col md={9}>
                            {moment(auth.utime).fromNow()}
                            ({moment(auth.utime).format('YYYY-MM-DD HH:mm:ss')})
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <h2>기타 메뉴 위치(탭메뉴)</h2>
                </Col>
            </Row>
        </>);
    }
    else {
        return <>Loading Profile...</>
    }
};

export default MemberDetailPage;